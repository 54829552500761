<template>
  <b-card
      class="brand-edit-wrapper"
  >
    <!-- form -->
    <b-form id="brandForm" class="edit-form mt-2">
      <b-row>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="品牌全称"
              label-for="name"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="name"
                size="sm"
                v-model="brand.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="品牌昵称"
              label-for="short_name"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="short_name"
                size="sm"
                v-model="brand.short_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="是否白牌"
              label-for="is_white"
              label-size="sm"
              class="mb-1"
          >
            <b-form-checkbox
                name="check-button"
                switch
                inline
                id="is_white"
                v-model="brand.is_white"
                value="1"
                unchecked-value="0"
            ></b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col md="12">
          <span style="font-weight: bolder">品牌备注</span>
          <b-form-textarea placeholder="添加品牌备注..." v-model="brand.memo"/>

        </b-col>
        <b-col md="12">
          <div style="font-weight: bolder; font-size: larger; color: crimson; margin-top: 20px">
            <span>提示！系统提示修改成功即可，列表页数据更新会有延迟！</span>
          </div>
        </b-col>
        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="save"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from "@/store";
import brandStore from './groupbrandStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    vSelect,
    useToast,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      brand: {},
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('groupbrand')) store.registerModule('groupbrand', brandStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('groupbrand')) store.unregisterModule('groupbrand')
    })

    const edit = function () {
      store.dispatch('groupbrand/edit', {id: this.id}).then(res => {
        this.brand = res.data.data
      })
    }

    const view = function () {
      store.dispatch('groupbrand/view', {id: this.id}).then(res => {
        this.brand = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      if (this.brand.name == undefined || this.brand.name == "") {
        toast.error("请填写品牌全称!")
        return false
      }
      store.dispatch('groupbrand/save', this.brand).then(res => {
        if (res.data.code == 0) {
          toast.success(res.data.data)
          this.$router.push({name: 'apps-groupbrand-list'});
        } else {
          toast.error(res.data.data)
        }

      })
    }

    return {
      edit,
      view,
      cancel,
      save,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    if (this.id !== 0) {
      this.edit()
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.custom-switch .custom-control-label::before {
  margin-top: 5px;
}
</style>
